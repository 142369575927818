import React from 'react';

function formatDateInPDT(inputDate) {
    // Parse the date
    const date = new Date(`${inputDate} 00:00:00 PDT`);

    // Format the date as 'YYYY-MM-DD'
    return date.toISOString().split('T')[0];
}

function ArticleSchema({
    pathname,
    headline,
    datePublished,
    dateModified,
    authorName,
    imageUrl,
    description
}) {
    const schemaJson = {
        "@context": "http://schema.org",
        "@type": "Article",
        "@id": 'https://rfdance.com/' + pathname,
        "headline": headline,
        "url": 'https://rfdance.com/' + pathname,
        "datePublished": formatDateInPDT(datePublished),
        "dateModified": formatDateInPDT(dateModified),
        "author": {
            "@type": "Person",
            "name": authorName
        },
        "image": 'https://rfdance.com' + imageUrl,
        "description": description
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaJson) }}
        />
    );
}

export default ArticleSchema;
